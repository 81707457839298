.flexContainer {
	align-items: center;
	display: flex;
	justify-content: flex-start;
	gap: 10px;

	&.spaceBetween {
		justify-content: space-between;
		padding-bottom: 0;
	}

	&.alignStart {
		align-items: flex-start;
	}
}
