@import '../../variables.scss';

.memberImport {
    background: transparent;

    .buttonPanel {
        justify-content: flex-end;

        button {
            margin: 0!important;
        }
    }

    .uploadButton {
        align-items: center !important;
        background-color: $grey_f3;
        color: $grey_85;
        cursor: pointer;
        padding: 10px !important;
        width: 100%;

        &.showButtonError {
            border-bottom: 2px solid $red_e4;
        }

        &.showButtonSuccess {
            border-bottom: 2px solid $blue_18;
            i {
                color: $blue_18;
            }
        }
    }
    .noMarginBottom {
        margin-bottom: 0px;
    }
    .uploadError {
        color: $red_e4;
        display: none;
        padding: 8px 0;
        &.showError {
            display: block;
        }
    }
    .uploadSuccess {
        color: $blue_18;
        display: none;
        padding: 8px 0;
        &.showSuccess {
            display: block;
        }
    }
    .uploadInputContainer {
        overflow: hidden;
    }

    .inputText {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}