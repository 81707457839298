@import '../../variables.scss';
@import '../global.module.scss';

.titleContainer {
	align-items: center;
	display: flex;
	justify-content: flex-start;
	max-width: calc(100% - 21px);

	.title {
		text-overflow: ellipsis;
		overflow: hidden;
		// Addition lines for 2 line or multiline ellipsis
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
		word-break: break-word;
	}
}

.infoButton {
	color: $black_43;
	font-size: 16px;
	margin: 0 0 0 10px;
}

.panelHeader {
	font-weight: bold;
}

:global {
	.ms-Callout .ms-Button--command {
		height: 21px;
		width: 21px;
		padding: 0;

		.ms-Button-flexContainer .ms-Icon {
			color: $black;
			font-size: 12px;
			height: 21px;
			padding: 0;
			&:hover {
				color: $violet_62;
				font-weight: bold;
			}
		}
	}
}

.description {
	p {
		margin: 0;
		min-height: 21px;
	}
	a {
		color: $violet_62;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
