@import '../../variables.scss';

.attendeesGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.attendeesListTitle {
	margin: 6px 0 18px 0;
	font-size: 14px;
	font-weight: 400;
	white-space: nowrap;
}
