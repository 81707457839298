@import '../../variables.scss';

.resultRatingGeneralContainer {
	margin-top: 16px;
	display: flow-root;
}

.averageResult {
	color: $black_43;
	font-size: 14px;
	font-weight: 600;

	background-color: $grey_f5;
	width: 38px;
	height: 38px;
	border-radius: 5px;

	display: block;
	float: left;
	text-align: center;
	line-height: 38px;
}

.answerTitle {
	font-size: 14px;
	font-weight: 600;
	color: $black_43;
	line-height: 38px;

	display: block;
	float: left;
	margin-left: 10px;

    max-width: calc(100% - 90px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.detailsButton {
	height: 38px;
	border-width: 0;
	display: block;
	margin-left: 10px;
	float: left;

	:global {
		.ms-Icon {
			color: $black_43 !important;
		}
	}
}

.resultAnswerContainer {
	clear: left;
	float: left;
	margin-left: 42px;
	margin-top: 8px;

	color: #1893a0;

	i {
		margin-right: 0.5em;
	}

	.ratingEmpty {
		position: relative;
		float: left;
	}
	.ratingFill {
		position: absolute;
		top: 0;
		left: 0;

		white-space: nowrap;
		overflow: hidden;
	}
}
