@import '../../variables.scss';

.surveyContainer {
	background-color: transparent;

	.iconColorBlack :global(.ms-Icon) {
		color: $black_21;
	}

	h1 {
		align-items: center;
		color: $black_21;
		font-size: 21px;
		font-weight: 600;
		margin: 0;
		display: flex;
		white-space: nowrap;
		> i {
			line-height: 28px;
			padding: 5px 11px 0 5px;
		}
		.editTitle {
			width: 420px;
		}
	}

	h2 {
		color: $grey_73;
		font-size: 14px;
		font-weight: 500;
	}

	.metadataContainer {
		margin-top: 5px;

		font-size: 14px;
		font-weight: normal;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		.sessionIdContainer {
			background-color: #737373;
			border-radius: 4px;
			height: 28px;
			padding: 0px 10px;
			margin-right: 16px;
			span {
				color: #ffffff;
				line-height: 28px;
			}
			:global(.ms-Button-textContainer .ms-Button-label) {
				color: #ffffff;
				&:hover {
					text-decoration: none;
				}
			}
			:global(.ms-Icon) {
				color: #ffffff;
			}
		}
	}

	.sessionId {
		font-weight: bold;
		margin-left: 5px;
	}

	.membersContainer {
		color: $grey_85;
		font-size: 14px;
		display: flex;
		margin-right: 12px;

		.member,
		.memberCount {
			padding: 0 5px 0 0;
		}

		.memberCount {
			padding-left: 5px;
		}
		i {
			font-size: 20px;
		}
	}

	.dateContainer {
		margin-right: 0;
	}

	.listHeaderContainer {
		align-items: center;
		color: #737373;
		display: flex;
		justify-content: space-between;
		margin-top: 15px;

		:global {
			.ms-Button--primary {
				border-radius: 4px;
				height: 35px;
			}
		}
	}

	.moreImageButton {
		height: 32px;
		:global(.ms-Button-flexContainer) {
			background-image: url('../../images/more-icon.svg'), url('../../images/more-icon-hover.svg');
			background-position: center center, -1000px -1000px;
			background-repeat: no-repeat, no-repeat;
			height: 32px;
			width: 32px;
			i {
				display: none;
			}
		}
		:global(.ms-Button) {
			height: 40px;
		}

		:global(.ms-Button--primary) {
			border-radius: 4px;
			height: 35px;
		}
	}

	.moreImageButton {
		height: 32px;
		:global(.ms-Button-flexContainer) {
			background-image: url('../../images/more-icon.svg'), url('../../images/more-icon-hover.svg');
			background-position: center center, -1000px -1000px;
			background-repeat: no-repeat, no-repeat;
			height: 32px;
			width: 32px;
			i {
				display: none;
			}
		}
		:global(.ms-Button) {
			height: 32px;
		}

		&:hover {
			:global(.ms-Button-flexContainer) {
				background-position: -1000px -1000px, center center;
			}
		}
	}
}
.archive {
	:global {
		.ms-ContextualMenu-header {
			height: auto !important;
		}
		.ms-ContextualMenu-linkContent {
			height: auto !important;
		}
		.ms-ContextualMenu-checkmarkIcon,
		.ms-ContextualMenu-icon {
			display: none;
		}
		.ms-ContextualMenu-itemText {
			color: $grey_73;
			height: auto;
			margin: 12px 4px;
			line-height: 12px;
			font-size: 12px;
		}
	}
}
