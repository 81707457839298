@import '../../variables.scss';

.field {
	position: absolute;
	right: 16px;
	top: 0;
	bottom: 0;
}

.error > i {
	color: $red_e4;
}

.warn > i {
	color: $yellow_b1;
}