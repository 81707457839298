@import '../../variables.scss';

.panelHeader {
	display: flex;
	align-items: center;
	gap: 0 10px;
	flex-wrap: wrap;

	.lastChanged {
		font-size: 12px;
		font-weight: 400;
		display: contents;
	}
}

.memberform {
	background-color: transparent;
	.nameContainer {
		align-items: baseline;
		display: flex;
		font-size: 16px;
		padding: 2px 10px;
		.name {
			padding-left: 6px;
		}
		> div {
			margin: 0 !important;
		}
	}
	.searchBox {
		padding: 0;
		margin: 0;
		:global {
			.ms-SearchBox {
				background-color: $grey_f3;
				border: transparent;
				&::after {
					display: none;
				}
			}
			.ms-SearchBox-field,
			.ms-SearchBox-iconContainer {
				color: $black_43;
			}
			.ms-SearchBox-field:focus {
				border-bottom: 2px solid transparent;
			}
			.ms-SearchBox-field::placeholder {
				color: $grey_85;
			}
		}
	}
	.combinedTableField {
		background-color: $grey_f3;
		margin-right: 10px;
		gap: 0;
		:global {
			.ms-TextField {
				flex-grow: 1;
				margin-right: 0;
			}
			.ms-TextField-fieldGroup {
				margin-right: 0;
			}
		}

		border-bottom: solid 2px transparent;

		&.error {
			border-bottom: solid 2px $red_e4;
		}
		&.warn {
			border-bottom: solid 2px $yellow_b1;
		}
		&.focus {
			border-bottom: solid 2px $violet_62;
			input:focus {
				border-bottom: 0;
				margin-top: 0;
			}
		}
	
	}

	.searchHeader {
		margin: 40px 0 10px 0;
		display: grid;
		grid-template-columns: 1fr 1fr 120px 60px 54px;

		.addMemberButton {
			grid-column: 3 / span 3;
		}
	}

	.voteWeightPercent {
		color: $red_e4;
		padding: 4px 0;
		display: flex;
		justify-content: space-between;
		&.voteWeightPercentMatch {
			color: $blue_18;
		}

		> *:last-child {
			margin-right: 15px;
		}
	}
	:global {
		.ms-TextField-field[aria-invalid='true'] {
			border-bottom: 2px solid $red_c7;
			margin-top: 2px;
		}
		.ms-TextField-field {
			line-height: 1em;
		}
		.ms-TextField > span {
			display: none;
		}
	}
	.switchMarginBottom {
		margin-bottom: 8px;
	}
	.onlineCounter {
		margin-left: 10px;
	}
	.addMemberButton {
		margin-top: 2px;
		margin-right: 0px;
		> * {
			margin-right: 0px;
		}
	}
	.searchBox {
		align-items: center;
		background-color: $grey_f3;
		border-bottom: 2px solid transparent;
		display: flex;
		margin-top: 2px;
		margin-right: 10px;
		&.searchBoxFocus {
			border-bottom: 2px solid $violet_62;
		}
		:global {
			.ms-TextField-field:focus {
				border-bottom: 0px solid transparent;
				margin-top: 0;
			}
			button:hover {
				background-color: transparent;
			}
		}
		.searchZoomIconButton {
			cursor: default;
			&:hover {
				color: $black_43;
				i {
					font-weight: normal;
				}
			}
		}
		.searchIconButton {
			color: $black_43;
			&:hover {
				color: $violet_62;
				i {
					font-weight: 700;
				}
			}
		}
	}
	:global(.error) .onlineStatus {
		display: none;
	}

	td {
		position: relative;
	}

	h1 {
		width: auto;
	}

	.votrInfoIcon {
		margin-bottom: 10px;
	}
}
