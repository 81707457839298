@import '../../variables.scss';

.legendContainer {
	padding-top: 44px;
}

.legendItemContainer {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

.detailsButton {
	height: 38px;
	border-width: 0;
	top: -6px;
	float: left;

	:global {
		.ms-Icon {
			color: $black_43 !important;
		}
	}
}

.answerTextContainer {
	display: flex;
	gap: 10px;
	width: 50%;

	.answerText {
		font-size: 14px;
		font-weight: 700;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.votesCountButtonContainer {
	display: flex;
	gap: 6px;
}

.votesCount {
	display: flex;
	justify-content: end;
}
