@import '../../variables.scss';

.subTitle {
	height: 38px !important;
	overflow: hidden;
}

.averageResult {
	color: $black_43;
	font-size: 14px;
	font-weight: 600;

	background-color: $grey_e3;
	width: 38px;
	height: 38px;
	border-radius: 5px;

	display: block;
	float: left;
	text-align: center;
	line-height: 38px;
}

.answerTitle {
	font-size: 14px;
	font-weight: 600;
	color: $black_43;
	line-height: 38px;

	display: block;
	float: left;
	margin-left: 10px;

	max-width: calc(100% - 48px);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.result {
	h6 {
		font-size: 16px;
		line-height: 24px;
		margin: 16px 0;
		font-weight: normal;
	}
}

.memberList {
	list-style: none;
	margin: 0 0 16px 0;
	padding: 0;

	display: grid;
	grid-template-columns: 33.33% 33.33% 33.33%;
	gap: 10px 0;

	.memberItem {
		:global(.ms-Persona-primaryText),
		:global(.ms-Persona-secondaryText) {
			color: $black_43;
		}
	}
}
